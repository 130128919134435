import { useTranslation } from 'react-i18next';
import '../../styles/navigation.css'

const Navigation = () => {

    const { t } = useTranslation();

    const handleOpenForm = (e) => {
        e.preventDefault();
        const formTry = document.querySelector('#form-try');
        formTry.style.display = 'flex';
    }

    return (
        <>
            <div className="header-wrap" style={{ zIndex: 999999 }}>
                <header>
                    <div className="navbar-wrapper">
                        <div className="navbar navbar-default navbar-inline navbar-scale">
                            <div className="container">
                                <div className="navbar-brand">
                                    <a href="/" title="Finio - správa a vymáhání pohledávek">
                                        <img src="https://www.finio.cz/themes/Drym2/media/images/logoStd.png"
                                            alt="Finio - správa a vymáhání pohledávek" className="logo" />
                                    </a>
                                </div>

                                <ul className="nav navbar-nav navbar-collapse-nav full-width" style={{ position: 'relative' }}>


                                    <li className="" style={{ display: "list-item" }}>
                                        <a href="https://www.finio.cz/" className="" title="Úvodní">Úvodní </a>
                                    </li>


                                    <li className="has-submenu" style={{ position: 'relative', display: "list-item" }}>
                                        <a href="https://www.finio.cz/komplexni-vymahani" className="has-submenu"
                                            title="Komplexní vymáhání">Komplexní vymáhání </a>
                                        <ul className="navbar-nav-submenu"
                                            style={{ width: 1440 + 'px', paddingLeft: 140 + 'px', paddingRight: 140 + 'px', left: -698.906 + 'px' }}>


                                            <li className="has-submenu titlelink hiddenOnMobile">
                                                <a href="https://www.finio.cz/komplexni-vymahani"
                                                    className="has-submenu        titlelink hiddenOnMobile" title="Komplexní vymáhání">Komplexní
                                                    vymáhání </a>
                                                <ul className="navbar-nav-submenu">


                                                    <li className="">
                                                        <a href="https://www.finio.cz/mimosoudni-vymahani" className=""
                                                            title="Mimosoudní vymáhání">Mimosoudní vymáhání </a>
                                                    </li>


                                                    <li className="">
                                                        <a href="https://www.finio.cz/soudni-a-exekucni-vymahani" className=""
                                                            title="Soudní a exekuční vymáhání">Soudní a exekuční vymáhání </a>
                                                    </li>
                                                </ul>
                                            </li>


                                            <li className="has-submenu titlelink">
                                                <a href="https://www.finio.cz/pomuzeme-vam-take"
                                                    className="has-submenu        titlelink" title="Pomůžeme Vám také">Pomůžeme Vám
                                                    také </a>
                                                <ul className="navbar-nav-submenu">


                                                    <li className="">
                                                        <a href="https://www.finio.cz/pomuzeme-vam-take#prihlasky" className=""
                                                            title="Podávání přihlášek do insolvenčního řízení">Podávání přihlášek do
                                                            insolvenčního řízení </a>
                                                    </li>


                                                    <li className="">
                                                        <a href="https://www.finio.cz/pomuzeme-vam-take#prihlasky-dedictvi" className=""
                                                            title="Podávání přihlášek do dědického řízení">Podávání přihlášek do
                                                            dědického řízení </a>
                                                    </li>


                                                    <li className="">
                                                        <a href="https://www.finio.cz/pomuzeme-vam-take#prevence" className=""
                                                            title="Prevence vzniku pohledávek">Prevence vzniku pohledávek </a>
                                                    </li>


                                                    <li className="">
                                                        <a href="https://www.finio.cz/pomuzeme-vam-take#ranne" className=""
                                                            title="Ranné vymáhání">Ranné vymáhání </a>
                                                    </li>


                                                    <li className="">
                                                        <a href="https://www.finio.cz/pomuzeme-vam-take#odkupy" className=""
                                                            title="Odkupy portfolií pohledávek">Odkupy portfolií pohledávek </a>
                                                    </li>


                                                    <li className="">
                                                        <a href="https://www.finio.cz/pomuzeme-vam-take#rozhodci-rizeni" className=""
                                                            title="Rozhodčí řízení">Rozhodčí řízení </a>
                                                    </li>


                                                    <li className="">
                                                        <a href="https://www.finio.cz/pomuzeme-vam-take#dohlizeci-rizeni" className=""
                                                            title="Dohlížecí řízení">Dohlížecí řízení </a>
                                                    </li>


                                                    <li className="">
                                                        <a href="https://www.finio.cz/pomuzeme-vam-take#poradenstvi" className=""
                                                            title="Poradenské služby">Poradenské služby </a>
                                                    </li>


                                                    <li className="">
                                                        <a href="https://www.finio.cz/pomuzeme-vam-take#mezinarodni-vymahani"
                                                            className="" title="Mezinárodní vymáhání">Mezinárodní vymáhání </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>


                                    <li className="" style={{ display: "list-item" }}>
                                        <a href="https://www.finio.cz/proc-finio" className="" title="Proč FINIO?">Proč FINIO? </a>
                                    </li>


                                    <li className="" style={{ display: "list-item" }}>
                                        <a href="https://www.finio.cz/o-nas" className="" title="O nás">O nás </a>
                                    </li>


                                    <li className="" style={{ display: "list-item" }}>
                                        <a href="https://www.finio.cz/kvalifikace" className="" title="Kvalifikace">Kvalifikace </a>
                                    </li>


                                    <li className="" style={{ display: "list-item" }}>
                                        <a href="https://www.finio.cz/kariera" className="" title="Kariéra">Kariéra </a>
                                    </li>


                                    <li className="" style={{ display: "list-item" }}>
                                        <a href="https://www.finio.cz/kontakt" className="" title="Kontakt">Kontakt </a>
                                    </li>
                                </ul>


                                <div className="navbar-right">
                                    <div className="navbar-toggle-btns">
                                        <div className="navbar-toggle-btn visible-xs-table visible-sm-table">
                                            <a className="js-navbar-toggle-nav" href="#">
                                                <svg className="ai ai-bars icon">
                                                    <use xlinkHref="https://www.finio.cz/#bars">
                                                        <svg id="bars" viewBox="0 0 32 32">
                                                            <path
                                                                d="M0 3.813h32v3.188H0zM0 25h32v3.188H0zm0-10.562h32v3.125H0z"></path>
                                                        </svg>
                                                    </use>
                                                </svg>
                                                Menu
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="ilink-content" style={{ width: 100 + '%', position: 'relatve', zIndex: 99999, marginTop: 0 }}>
                    <a style={{ marginTop: 0 + 'px', width: 500 + 'px' }} href="#" onClick={handleOpenForm} className="btn btn-primary w100"
                        rel="undefined noopener noreferrer">
                        <span>{t('help.banner')}</span>
                        <span style={{ position: 'relative', top: 4 + 'px', left: 5 + 'px' }}>
                            <svg className="ai ai-arrow">
                                <use xlinkHref="https://www.finio.cz/#arrow"></use>
                            </svg>
                        </span>
                    </a>
                </div>
            </div>

            <nav className="respond_sidenav"><a href="#" className="sidenav-close">
                <svg className="ai ai-cross45">
                    <use xlinkHref="#cross45"></use>
                </svg>
            </a>
                <ul className="primary-nav">


                    <li className="active">
                        <a className="active">Úvodní</a>
                    </li>


                    <li className="has-submenu">
                        <a href="https://www.finio.cz/komplexni-vymahani" className="has-submenu" title="Komplexní vymáhání">Komplexní
                        vymáhání
                            <svg className="ai ai-right65">
                                <use xlinkHref="#right65"></use>
                            </svg>
                        </a>
                        <ul className="secondary-nav is-hidden">
                            <li className="go-back"><a href="https://www.finio.cz/komplexni-vymahani">
                                <svg className="ai ai-right65">
                                    <use xlinkHref="#right65"></use>
                                </svg>
                                    Zpět</a></li>
                            <li className="section-parent"><a href="https://www.finio.cz/komplexni-vymahani">Komplexní vymáhání
                                    <svg className="ai ai-right65">
                                    <use xlinkHref="#right65"></use>
                                </svg>
                            </a></li>


                            <li className="has-submenu titlelink hiddenOnMobile">
                                <a href="https://www.finio.cz/komplexni-vymahani"
                                    className="has-submenu        titlelink hiddenOnMobile" title="Komplexní vymáhání">Komplexní vymáhání
                                    <svg className="ai ai-right65">
                                        <use xlinkHref="#right65"></use>
                                    </svg>
                                </a>
                                <ul className="secondary-nav is-hidden">
                                    <li className="go-back"><a href="https://www.finio.cz/komplexni-vymahani">
                                        <svg className="ai ai-right65">
                                            <use xlinkHref="#right65"></use>
                                        </svg>
                                            Zpět</a></li>
                                    <li className="section-parent"><a href="https://www.finio.cz/komplexni-vymahani">Komplexní vymáhání
                                            <svg className="ai ai-right65">
                                            <use xlinkHref="#right65"></use>
                                        </svg>
                                    </a></li>


                                    <li className="">
                                        <a href="https://www.finio.cz/mimosoudni-vymahani" className="" title="Mimosoudní vymáhání">Mimosoudní
                                            vymáhání </a>
                                    </li>


                                    <li className="">
                                        <a href="https://www.finio.cz/soudni-a-exekucni-vymahani" className=""
                                            title="Soudní a exekuční vymáhání">Soudní a exekuční vymáhání </a>
                                    </li>
                                </ul>
                            </li>


                            <li className="has-submenu titlelink">
                                <a href="https://www.finio.cz/pomuzeme-vam-take" className="has-submenu        titlelink"
                                    title="Pomůžeme Vám také">Pomůžeme Vám také
                                    <svg className="ai ai-right65">
                                        <use xlinkHref="#right65"></use>
                                    </svg>
                                </a>
                                <ul className="secondary-nav is-hidden">
                                    <li className="go-back"><a href="https://www.finio.cz/pomuzeme-vam-take">
                                        <svg className="ai ai-right65">
                                            <use xlinkHref="#right65"></use>
                                        </svg>
                                            Zpět</a></li>
                                    <li className="section-parent"><a href="https://www.finio.cz/pomuzeme-vam-take">Pomůžeme Vám také
                                            <svg className="ai ai-right65">
                                            <use xlinkHref="#right65"></use>
                                        </svg>
                                    </a></li>


                                    <li className="">
                                        <a href="https://www.finio.cz/pomuzeme-vam-take#prihlasky" className=""
                                            title="Podávání přihlášek do insolvenčního řízení">Podávání přihlášek do insolvenčního
                                            řízení </a>
                                    </li>


                                    <li className="">
                                        <a href="https://www.finio.cz/pomuzeme-vam-take#prihlasky-dedictvi" className=""
                                            title="Podávání přihlášek do dědického řízení">Podávání přihlášek do dědického
                                            řízení </a>
                                    </li>


                                    <li className="">
                                        <a href="https://www.finio.cz/pomuzeme-vam-take#prevence" className=""
                                            title="Prevence vzniku pohledávek">Prevence vzniku pohledávek </a>
                                    </li>


                                    <li className="">
                                        <a href="https://www.finio.cz/pomuzeme-vam-take#ranne" className="" title="Ranné vymáhání">Ranné
                                            vymáhání </a>
                                    </li>


                                    <li className="">
                                        <a href="https://www.finio.cz/pomuzeme-vam-take#odkupy" className=""
                                            title="Odkupy portfolií pohledávek">Odkupy portfolií pohledávek </a>
                                    </li>


                                    <li className="">
                                        <a href="https://www.finio.cz/pomuzeme-vam-take#rozhodci-rizeni" className=""
                                            title="Rozhodčí řízení">Rozhodčí řízení </a>
                                    </li>


                                    <li className="">
                                        <a href="https://www.finio.cz/pomuzeme-vam-take#dohlizeci-rizeni" className=""
                                            title="Dohlížecí řízení">Dohlížecí řízení </a>
                                    </li>


                                    <li className="">
                                        <a href="https://www.finio.cz/pomuzeme-vam-take#poradenstvi" className=""
                                            title="Poradenské služby">Poradenské služby </a>
                                    </li>


                                    <li className="">
                                        <a href="https://www.finio.cz/pomuzeme-vam-take#mezinarodni-vymahani" className=""
                                            title="Mezinárodní vymáhání">Mezinárodní vymáhání </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>


                    <li className="">
                        <a href="proc-finio" className="" title="Proč FINIO?">Proč FINIO? </a>
                    </li>


                    <li className="">
                        <a href="o-nas" className="" title="O nás">O nás </a>
                    </li>


                    <li className="">
                        <a href="kvalifikace" className="" title="Kvalifikace">Kvalifikace </a>
                    </li>


                    <li className="">
                        <a href="https://www.finio.cz/kariera" className="" title="Kariéra">Kariéra </a>
                    </li>


                    <li className="">
                        <a href="kontakt" className="" title="Kontakt">Kontakt </a>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Navigation;
