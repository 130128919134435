import {
    HashRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import i18n from "i18next";
import { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from "react-i18next";


import './styles/main.css';

// STEP COMPONENETS
import Home from './components/Home';
import Navigation from './components/partials/Navigation';
import Lead from './components/Lead';
import Product from './components/Product';
import Pay from './components/Pay';

// PARTIAL COMPONENTS
import Footer from './components/partials/Footer';
import Wolves from './components/partials/Wolves';
import References from './components/partials/References';

import en from "./assets/languages/en.json";
import cs from "./assets/languages/cs.json";
import { LanguageSwitcher } from './components/LanguageSwitcher';
import Done from "./components/Done";
import TryUs from "./components/TryUs";
import Cancelled from "./components/Cancelled";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en,
            cs
        },
        fallbackLng: "cs",

        interpolation: {
            escapeValue: false,
        }
    });

const App = () => {
    const [step, setStep] = useState(1);
    const [data, setData] = useState('');

    useEffect(() => {
        /*
            Insert data from the local storage
            - in case the user didn't finish the order or just wants to return back during the payment process
            - local storage will be cleared if the payment was successful
        */ 

        const localStorageData = JSON.parse(localStorage.getItem('data'))

        if (data == '' && localStorageData !== null) {

            let date = new Date(localStorageData.date) // get date from the local storage
            date.setDate(date.getDate() + 1) // add one day to the date

            // if 1 day has elapsed
            if (new Date() > date) {
                localStorage.clear()
                console.log('uplynulo 24h od vyplnění -> mažu předvyplněná data')
            } else {
                setData(localStorageData)
            }
        }
    }, [])

    const validateEmail = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return true;
        }

        return false;
    };

    const throwMsg = (message, element, duration) => {
        element.style.display = "block";
        element.innerHTML = message;
        setTimeout(() => {
            element.style.opacity = 1;
        }, 100);
        setTimeout(() => {
            element.style.opacity = 0;
            element.style.display = "none";
        }, duration);
    };

    return (
        <div className="App">
            <LanguageSwitcher />

            <Navigation />
            <TryUs />
            <div className="finio-app">
                <Router>
                    <Switch>
                        <Route path="/" exact render={() => <Home setStep={setStep} />} />
                        <Route path="/krok1" exact render={() =>
                            <Lead setStep={setStep}
                                  step={step}
                                  data={data}
                                  setData={setData}
                                  validateEmail={validateEmail}
                                  throwMsg={throwMsg} />
                        } />
                        <Route path="/krok2" exact render={() =>
                            <Product setStep={setStep}
                                     step={step}
                                     data={data}
                                     setData={setData}
                                     validateEmail={validateEmail}
                                     throwMsg={throwMsg} />
                        } />
                        <Route path="/krok3" exact render={() =>
                            <Pay setStep={setStep}
                                 step={step}
                                 data={data}
                                 setData={setData}
                                 throwMsg={throwMsg} />
                        } />
                        <Route path="/done" exact render={() =>
                            <Done setStep={setStep} />
                        } />
                        <Route path="/cancelled" exact render={() =>
                            <Cancelled setStep={setStep} />
                        } />
                    </Switch>
                </Router>
            </div>
            <Wolves step={step} />
            <References />
            <Footer />
        </div>
    );
};

export default App;
