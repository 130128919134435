import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Arrow from '../assets/arrow_svg.svg'
import '../styles/lead.css'

const Lead = (props) => {
    const location = useLocation()
    const { t } = useTranslation()

    useEffect(() =>{
        props.setStep(4);

        // After successful payment clear local storage
        localStorage.clear()

        setTimeout(() => {
            window.location = 'https://finio.cz';
        }, 10000)
    }, [])

    return (
        <section className="lead-form-page page">
            <div className="header">
                <div className="header__title">
                    <h1>
                        {t("done.header")}
                    </h1>
                </div>
            </div>
            <div className="done">
                <h2>{t("done.subheader")}</h2>
                <p>{t("done.questions")}</p>
            </div>

            <div className="type-first">
                <div className="type-first__buttons">
                    <Link to='/'>
                        <div className="type-first__button button flex">
                            <p>
                                <Trans i18nKey="done.button"></Trans>
                            </p>
                            <img className="button__arrow" src={Arrow} />
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Lead;
