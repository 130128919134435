import ReklamacniRad from '../../assets/reklamacni-rad-28.6..pdf'
import PlatebniPodminky from '../../assets/platebni-podminky-13.7..pdf'

import ComGate from '../../assets/comgate-paticka-e-shopu.png'

const Footer = () => {
    return (
        <>
            <div className="container">
                <div className="row bottom-footer">
                    <div className="col-lg-9 col-md-8 infoFooter">
                        <div className="logoFooter"
                            style={{backgroundImage: 'url(https://www.finio.cz/_static/img/logoFooter.png)'}}></div>
                        <div className="footertext">
                            <div className="footer-menu">
                                <ul className="">
                                    <li className="active">
                                    <a className="" title="Úvodní" href="https://www.finio.cz/">Úvodní</a>
                                    </li>
                                    <li className="">
                                    <a className="" title="Komplexní vymáhání" href="https://www.finio.cz/komplexni-vymahani">Komplexní vymáhání</a>
                                    </li>
                                    <li className="">
                                    <a className="" title="Proč FINIO?" href="https://www.finio.cz/proc-finio">Proč FINIO?</a>
                                    </li>
                                    <li className="bold">
                                    <a className="bold" title="GDPR" href="https://www.finio.cz/zpracovani-osobnich-udaju">GDPR</a>
                                    </li>
                                    <li className="bold">
                                    <a className="bold" title="Smlouva o zpracování osobních údajů" href="https://www.finio.cz/smlouva-o-zpracovani-osobnich-udaju">Smlouva o zpracování osobních údajů</a>
                                    </li>
                                    <li className="bold">
                                    <a className="bold" title="Všeobecné obchodní podmínky" href="https://www.finio.cz/vop">Všeobecné obchodní podmínky</a>
                                    </li>
                                    <li className="bold">
                                    <a className="bold" title="Reklamační řád" href={ReklamacniRad}>Reklamační řád</a>
                                    </li>
                                    <li className="bold">
                                    <a className="bold" title="Platební podmínky" href={PlatebniPodminky}>Platební podmínky</a>
                                    </li>
                                    <li className="">
                                    <a className="" title="Kontakt" href="https://www.finio.cz/kontakt">Kontakt</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footerText" style={{marginTop: '25px'}}>
                                <span> Finio a.s. | SPRÁVA A VYMÁHÁNÍ POHLEDÁVEK</span><br />
                                <div className="contacts">
                                    <a href="tel:+420255702071" className="phone-link">
                                        <svg className="ai ai-phone-call">
                                            <use xlinkHref="https://www.finio.cz/#phone-call"></use>
                                        </svg>
                                        +420 255 702 071</a>
                                    <a href="mailto:info@finio.cz" className="mail-link">
                                        <svg className="ai ai-house">
                                            <use xlinkHref="https://www.finio.cz/#house"></use>
                                        </svg>
                                        info@finio.cz</a>
                                    <span className="adress">
                                        Evropská 657/120, 160 00 Praha 6
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-3 col-md-4 pt10 footer-right">
                        <span className="copyright">© Všechna práva vyhrazena</span>&nbsp;<span className="firma">Finio a.s.</span>
                    </div>
                </div>

                <div className="payment-footer" style={{textAlign: 'center', paddingBottom: 30 + 'px'}}>
                    <a href="https://www.comgate.cz/cz/platebni-brana">
                        <img style={{maxWidth: 320 + 'px'}} src={ComGate} alt="Platební brána ComGate" />
                    </a>
                </div>
            </div>
        </>
    )
}

export default Footer;