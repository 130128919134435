import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Arrow from '../assets/arrow_svg.svg'
import '../styles/lead.css'

const Lead = (props) => {
    const location = useLocation()
    const { t } = useTranslation()

    return (
        <section className="lead-form-page page" style={{textAlign: 'center', position: 'relative', zIndex: 999}}>
            <div className="header">
                <div className="header__title">
                    <h1>
                        {t("cancelled.header")}
                    </h1>
                </div>
            </div>
            <div className="done">
                <h2>{t("cancelled.subheader")}</h2>
            </div>

            <Link to="/krok1" style={{display: 'block', marginTop: '50px', fontWeight: '700', fontSize: '2rem'}}>Vrátit se do e-shopu</Link>
        </section>
    )
}

export default Lead;
