import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';

const TryUs = () => {
    const { t } = useTranslation()

    const [name, setName] = useState('')
    const [firm, setFirm] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const checkRef = useRef('')

    const handleCloseForm = () => {
        const formTry = document.querySelector('#form-try')
        formTry.style.display = 'none'
    }

    const handleSendForm = () => {
        const inputWrappers = document.querySelectorAll('.form-group');
        const modalErr = document.querySelector('.modal-warning');
        const modalSuccess = document.querySelector('.modal-success');

        inputWrappers.forEach(wrapper => {
            const input = wrapper.querySelector('input');
            if (input.value == '') {
                wrapper.classList.add('has-error');
            } else {
                wrapper.classList.remove('has-error');
            }
        });

        if (name != '' && firm != '' && email != '' && checkRef.current.checked == true) {
            console.log(name + " " + firm + " " + email + " " + checkRef.current.checked);

            const formData = new FormData();
            formData.append('name-try', name);
            formData.append('firm-try', firm);
            formData.append('email-try', email);
            formData.append('form-try', phone);

            fetch('/checkout/auto_form-try.php', {
                method: 'POST',
                body: formData,
            })
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    console.log(result);
                    handleCloseForm();

                    modalSuccess.style.display = 'flex';
                    setTimeout(() => {
                        modalSuccess.style.display = 'none';
                    }, 2500);
                })
                .catch((e) => {
                    console.log('form try err:', e);
                });
            /*
            $.ajax({
                url: 'https://ipohledavky.finio.cz/auto_form-try.php',
                data:'name-try=' + nameRef.current.value + '&firm-try=' + firmRef.current.value + '&email-try=' + emailRef.current.value + '&phone-try=' + phoneRef.current.value,
                type: "POST",
                success:function(data){
                    console.log(data);
                    handleCloseForm();

                    modalSuccess.style.display = 'flex';
                    setTimeout(() => {
                        modalSuccess.style.display = 'none';
                    }, 2500);
                },
                error:function (err){
                    console.log('form try err:' + err);
                }
            });
             */

        } else {
            modalErr.style.display = 'block';
        }
    }

    return (
        <>
            <div className="modal-success">
                <p>{t('help.success')}</p>
            </div>

            <div className="modal fade modal-xs in" tabIndex="-1" role="dialog" id="form-try" aria-hidden="true"
                 style={{display: 'none', justifyContent: 'center', alignItems: 'center', zIndex: 99999999, width: 100 + 'vw', height: 100 + 'vh', background: 'rgba(0,0,0,0.5)'}}>
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" onClick={ handleCloseForm }>×</button>
                            <h2 style={{padding: '0 10px'}} className="modal-demand-title">{t('help.title')}</h2>
                        </div>
                        <div className="modal-body">
                            <div id="snippet-tryitForm-1-tryitForm">
                                <p className="modal-warning">{t('help.warning')}</p>
                                <form action="" method="post">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="name-try"
                                            id="frm-tryitForm-1-form-name"
                                            required=""
                                            className="form-control has-js-label"
                                            placeholder=""
                                            onBlur={ () => setName(document.querySelector('#frm-tryitForm-1-form-name').value) }
                                        />
                                        <label
                                            className="js-label"
                                            style={ name !== '' ? { opacity: 0 } : { opacity: 1 } }
                                        >
                                            {t('help.name')}*
                                        </label>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="firm-try"
                                            id="frm-tryitForm-1-form-firm"
                                            required
                                            className="form-control has-js-label"
                                            placeholder=""
                                            onBlur={ () => setFirm(document.querySelector('#frm-tryitForm-1-form-firm').value) }
                                        />
                                        <label
                                            className="js-label"
                                            style={ firm !== '' ? { opacity: 0 } : { opacity: 1 } }
                                        >
                                            {t('help.company')}*
                                        </label>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email-try"
                                            id="frm-tryitForm-1-form-email"
                                            required
                                            className="form-control has-js-label"
                                            placeholder=""
                                            onBlur={ () => setEmail(document.querySelector('#frm-tryitForm-1-form-email').value) }
                                        />
                                        <label
                                            className="js-label"
                                            style={ email !== '' ? { opacity: 0 } : { opacity: 1 } }
                                        >
                                            E-mail*
                                        </label>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="tel"
                                            name="phone-try"
                                            id="frm-tryitForm-1-form-phone"
                                            className="form-control has-js-label"
                                            placeholder=""
                                            onBlur={ () => setPhone(document.querySelector('#frm-tryitForm-1-form-phone').value) }
                                        />
                                        <label
                                            className="js-label"
                                            style={ phone !== '' ? { opacity: 0 } : { opacity: 1 } }
                                        >
                                            {t('help.phone')}
                                        </label>
                                    </div>


                                    <div className="col-sm-12 condition">
                                        <div className="form-group">
                                            <label className="fw-normal fs-sm text-graylight">
                                                <label htmlFor="frm-tryitForm-1-form-conditions">
                                                    <input
                                                        type="checkbox"
                                                        ref={ checkRef }
                                                        name="conditions"
                                                        id="frm-tryitForm-1-form-conditions"
                                                        required=""
                                                    />
                                                    <span>{t('help.gdpr.agree')} <a href="https://www.finio.cz/zpracovani-osobnich-udaju"
                                                              target="_blank" className="hlp-click"
                                                              rel="undefined noopener noreferrer" data-hasqtip="1">{t('help.gdpr.link')}</a>.
                                                    </span>
                                                    <div className="hide noPrint gdprUniversalHint">{t('help.gdprMore.agree')} <a href="https://www.finio.cz/zpracovani-osobnich-udaju"
                                                                     target="_blank" rel="undefined noopener noreferrer">{t('help.gdprMore.link')}</a>.</div>
                                                </label> </label>
                                            </div>
                                    </div>



                                    <div className="alignC">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={ () => handleSendForm()}
                                        >
                                            {t('help.send')}
                                        </button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TryUs
