import { useTranslation } from "react-i18next";
import "../styles/language-switcher.css";

import CS from '../assets/cs.png'
import EN from '../assets/en.png'

export function LanguageSwitcher() {
    const { i18n } = useTranslation();

    return <div className="language-switcher">
        <img src={CS} onClick={() => i18n.changeLanguage('cs')} />
        <img src={EN} onClick={() => i18n.changeLanguage('en')} />
    </div>;
}