import Wolf from '../../assets/wolf.png'
import Bubble from '../../assets/bubble.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Wolves = (props) => {
    const { t } = useTranslation();

    const [texts, setTexts] = useState([
        {
            first: '',
            second: 'wolves.first.right'
        },
        {
            first: '',
            second: 'wolves.second.right'
        },
        {
            first: '',
            second: 'wolves.third.right'
        },
        {
            first: '',
            second: ''
        }
    ])

    const [size, setSize] = useState([
        {
            first: {
                fontSize: 1.4 + 'em',
                padding: '50px 20px 20px 40px'
            },
            second: {
                fontSize: 1.125 + 'em',
                padding: '52.5px 50px 20px 30px'
            }
        },
        {
            first: {
                fontSize: 2 + 'em',
                padding: '60px 20px 20px 40px'
            },
            second: {
                fontSize: 1.4 + 'em',
                padding: '55px 40px 20px 20px'
            }
        },
        {
            first: {
                fontSize: 1.33 + 'em',
                padding: '55px 20px 20px 40px'
            },
            second: {
                fontSize: 1.5 + 'em',
                padding: '58px 35px 20px 20px'
            }
        },
        {
            first: {
                fontSize: 1.175 + 'em',
                padding: '57.5px 20px 20px 40px'
            },
            second: {
                fontSize: 1.225 + 'em',
                padding: '50px 40px 20px 20px'
            }
        }
    ])

    return (
        <>
        { texts[props.step - 1].first === '' && texts[props.step - 1].second === '' ? 
            '' :
            <div className="speech-bubbles">
                <div className="speech-bubbles_element speech-bubbles_element--left"
                    style={texts[props.step - 1].first !== '' ? { opacity: 1 } : { opacity: 0 }}>
                    <img src={Wolf} alt="wolf" />
                    <div className="bubble" style={{ backgroundImage: 'url(' + Bubble + ')' }}>
                        {/* <p style={size[props.step - 1].first}>{texts[props.step - 1].first}</p> */}
                        <p style={size[props.step - 1].first}>{t(texts[props.step - 1].first)}</p>
                    </div>
                </div>
                <div className="speech-bubbles_element speech-bubbles_element--right"
                    style={texts[props.step - 1].second !== '' ? { opacity: 1 } : { opacity: 0 }}>
                    <img src={Wolf} alt="wolf" />
                    <div className="bubble" style={{ backgroundImage: 'url(' + Bubble + ')' }}>
                        {/* <p style={size[props.step - 1].second}>{texts[props.step - 1].second}</p> */}
                        <p style={size[props.step - 1].second}>{t(texts[props.step - 1].second)}</p>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Wolves
