import { useEffect } from "react";
import { Link } from "react-router-dom";

import '../styles/home.css'

import Arrow from '../assets/arrow_svg.svg'
import { Trans, useTranslation } from "react-i18next";

const Home = (props) => {
    useEffect(() => {
        props.setStep(1);
    })

    const { t } = useTranslation();

    return (
        <section className="home page">
            <div className="header">
                <div className="header__title">
                    <h1>{t('home.header.title')}</h1>
                </div>
                <p className="header__text" >
                    <Trans i18nKey="home.header.text" components={[<br></br>]} ></Trans>
                    <b className="header__colored">{t('home.header.text-colored')}</b>
                </p>
            </div>

            <ul className="home__list">
                <li className="home__list__item">
                    <Trans i18nKey="home.list.item.1"></Trans>
                </li>
                <li className="home__list__item">
                    <Trans i18nKey="home.list.item.2"></Trans>
                </li>
                <li className="home__list__item">
                    <Trans i18nKey="home.list.item.3"></Trans>
                </li>
            </ul>

            <div className="type-first">
                <div className="type-first__buttons">
                    <Link to='/krok1'>
                        <div className="type-first__button button flex">
                            <p>
                                <Trans i18nKey="home.button"></Trans>
                            </p>
                            <img className="button__arrow" src={Arrow} />
                        </div>
                    </Link>
                </div>
            </div>
        </section >
    )
}

export default Home;