import { useTranslation } from 'react-i18next'
import '../../styles/references.css'
import Star from '../../assets/star.svg'

const References = () => {
    const { t } = useTranslation();

    const drawStars = count => {
        let stars = []
        
        for (let i = 0; i < count; i++) {
            stars.push(i)
        }

        return (
            stars.map(star => (
                <img src={ Star } />
            ))
        )
    }

    return(
        <div className="references">
            <ul className="references__list">
                {
                    t('references', { returnObjects: true }).map(reference => (
                        <li className="references__item">
                            <b>{ reference.name }, { reference.place }</b>
                            <p>{ reference.text }</p>
                            <div className="references__stars">
                                {
                                    drawStars(reference.stars)
                                }
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default References