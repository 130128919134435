import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import StepNav from '../components/partials/StepNav'

import Arrow from '../assets/arrow_svg.svg'
import Add from '../assets/plus.svg'
import '../styles/product.css'
import { useTranslation } from "react-i18next";

const Lead = (props) => {
    const { t } = useTranslation();

    const history = useHistory()
    const location = useLocation()

    const nameRef = useRef()
    const typeRef = useRef()
    const idRef = useRef()

    const [products, setProducts] = useState([])
    // const [count, setCount] = useState(0)

    useEffect(() => {
        // if data are empty or null redirect to the home page
        if (props.data == '' || props.data === null) {
            history.push('/')
        } else {
            // if there are already some products, import them then
            if (props.data.products) {
                setProducts(props.data.products)
                // setCount(props.data.products.length)
            }

            // if user chose himself
            if (props.data.myself === true) {
                nameRef.current.value = props.data.name
            }

            props.setStep(3);
        }
    }, [])

    const handleAddNew = () => {
        let ids = []

        if (nameRef.current.value !== '' && idRef.current.value !== '') {

            // if product already exists
            products.forEach(product => {
                ids.push(product.id)
            })

            if (ids.includes(idRef.current.value)) {
                console.log('already exists')
                props.throwMsg('Nemůžete stejnou položku přidat dvakrát.', document.querySelector('#product-err'), 3000)

                return
            }

            products.unshift({
                name: nameRef.current.value,
                idType: typeRef.current.value,
                id: idRef.current.value,
                price: 158
            })

            // setCount(products.length)

            console.log(products)
            setTimeout(() => {
                if (document.querySelector('.product__form')) {
                    document.querySelector('.product__form').reset() // reset form (input values) after submit
                }
            }, 500);
            props.throwMsg('Položka byla úspěšně přidána.', document.querySelector('#product-succ'), 3000)
            return true
        } else {
            props.throwMsg('Vyplňte, prosím, všechna povinná pole.', document.querySelector('#product-err'), 3000)
        }

    }

    const handleSubmit = () => {
        if (products.length > 0 && (nameRef.current.value === '' && idRef.current.value === '')) {
            props.setData({
                ...props.data,
                products: products,
                totalPrice: getPrice()
            })

            history.push('/krok3')
        } else if (products.length === 0 || products.length > 0 && (nameRef.current.value !== '' || idRef.current.value !== '')) {
            if (handleAddNew()) {
                props.setData({
                    ...props.data,
                    products: products,
                    totalPrice: getPrice()
                })

                history.push('/krok3')
            }
        }
    }

    const getPrice = () => {
        let price = 0;

        products.map(product => price += product.price)

        return price
    }

    return (
        <section className="product-page page">
            <StepNav location={location.pathname} name="O OVĚŘOVANÉ OSOBĚ"
                setData={props.setData}
                step={props.step}
                data={props.data}
                products={products}
                getPrice={getPrice} />
            <div className="header">
                <div className="header__title">
                    <h1>
                        {t('product.header')}
                    </h1>
                </div>
            </div>
            <div className="product">
                <div className="form-message form-message--err" id="product-err"></div>
                <div className="form-message form-message--succ" id="product-succ"></div>
                <div className="product__add product__col">
                    <form className="product__form">
                        <div className="input-wrapper">
                            <input className="input-full" type="text"
                                ref={nameRef}
                                placeholder={t('product.input.placeholder')} />
                        </div>
                        <div className="input-wrapper">
                            <select className="select-part" ref={typeRef}>
                                <option value="datum_narozeni">{t('product.select.option.1')}</option>
                                <option value="rodne_cislo">{t('product.select.option.2')}</option>
                                <option value="ic">{t('product.select.option.3')}</option>
                            </select>
                            <input className="input-part" type="text"
                                ref={idRef}
                                placeholder="*" />
                        </div>
                        <button className="product__btn"
                            onClick={handleAddNew}
                            type="button">
                            <img src={Add} alt="Přidat další" />
                            {t('product.button.add')}
                        </button>
                    </form>
                </div>
                {
                    /*
                    <div className="product__cart">
                        <strong>{t('product.text.total')}</strong>
                        <p>{count}</p>
                    </div>   
                    */
                }
            </div>

            <div className="type-first">
                <div className="type-first__buttons">
                    <button className="type-first__button button flex product__step-btn"
                        onClick={handleSubmit}
                        type="button">
                        <p>{t('product.button.next')}</p>
                        <img className="button__arrow" src={Arrow} />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Lead;
