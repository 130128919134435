import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from 'axios';

import StepNav from '../components/partials/StepNav'

import Arrow from '../assets/arrow_svg.svg'
import Trash from '../assets/trash.svg'
import '../styles/pay.css'
import { Trans, useTranslation } from "react-i18next";

const Lead = (props) => {
    const { t } = useTranslation();

    const location = useLocation()
    const history = useHistory()
    const newsletterRef = useRef()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const localStorageData = JSON.parse(localStorage.getItem('data'))


        // if data are empty or null redirect to the home page
        if (props.data == '' || props.data === null || props.data.products == '' || props.data.products === null) {
            // if there are data in the localstorage, load them, otherwise redirect to the home page
            if (localStorageData !== null) {
                props.setData(localStorageData)
                props.setStep(4);
            } else {
                history.push('/')
            }
        } else {
            props.setStep(4);
        }
    }, [])

    const handleDelete = (id) => {
        const tempProducts = props.data.products.filter(product => product.id != id)

        props.setData({ ...props.data, totalPrice: props.data.totalPrice - props.data.products[0].price, products: tempProducts })
    }

    const handlePay = async () => {
        // console.log(props.data)

        if (props.data.products.length === 0) {
            props.throwMsg('K dokončení objednávky musíte přidat alespoň jednu osobu.', document.querySelector('#product-err'), 3000)
        } else {
            const data = {...props.data, date: new Date()}

            console.log(data)
            
            // Store data to the local storage
            localStorage.setItem('data', JSON.stringify(data))

            setLoading(true)

            axios.post('/checkout/index.php/api/create-payment', { data })
            .then(res => {
                window.location.href = res.data.url
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const handleCheckNewsletter = () => {
        props.setData({
            ...props.data,
            newsletter: newsletterRef.current.checked
        })
    }

    const getType = (idType) => {
        switch (idType) {
            case 'datum_narozeni':
                // return 'Datum narození'
                return t('product.select.option.1')
            case 'rodne_cislo':
                // return 'Rodné číslo'
                return t('product.select.option.2')
            case 'ic':
                // return 'IČ'
                return t('product.select.option.3')
            default:
                break;
        }
    }

    return (
        <section className="pay-page page">
            <StepNav location={location.pathname} name="REKAPITULACE" step={props.step} data={props.data} />
            <div className="header">
                <div className="header__title">
                    <h1>
                        {t('pay.header')}
                    </h1>
                </div>
            </div>
            <div className="pay">
                <div className="form-message form-message--err pay__msg" id="product-err"></div>
                <ul className="pay__list">
                    {props.data.products ? props.data.products.length === 0 ? 'Nemáte přidané žádné položky.' :
                        props.data.products.map(product => {
                            return (
                                <li className="pay__item" key={product.id}>
                                    <div className="pay__description">
                                        <h3 className="pay__title">
                                            {t('pay.verification.product')}
                                        </h3>
                                        <p className="pay__name"><strong>{t('pay.verification.person')}:</strong> {product.name}</p>
                                        <p className="pay__id"><strong>{getType(product.idType)}: </strong> {product.id}</p>
                                        <p className="pay__price"><strong>{t('pay.verification.price')}:</strong> {product.price} ,- Kč</p>
                                    </div>
                                    <div className="pay__delete">
                                        <button onClick={() => handleDelete(product.id)}>
                                            <img className="button__arrow" src={Trash} />
                                        </button>
                                    </div>
                                </li>
                            )
                        })
                        : ''}
                </ul>

                <div className="pay__count">
                    <strong>
                        {t('pay.text.items')} { props.data.products ?
                            <strong style={{ color: '#e21a37', textShadow: '0 0 9px rgba(226, 26, 55, .65)' }}>
                                { props.data.products.length }
                            </strong> 
                            : 
                            <strong style={{ color: '#e21a37', textShadow: '0 0 9px rgba(226, 26, 55, .65)' }}>
                                0
                            </strong>
                        } <br />
                        {t('pay.text.total', { price: props.data.totalPrice })} 
                    </strong>
                </div>

                <div className="form-check pay__check">
                    <input type="checkbox" className="lead__check" ref={newsletterRef}
                        onChange={handleCheckNewsletter} />
                    <label className="gdpr-line">
                        {t('pay.checkbox')}
                    </label>
                </div>
            </div>

            <div className="type-first">
                <div className="type-first__buttons">
                    <div className="type-first__button button flex pay__step-btn"
                        onClick={handlePay}
                        style={loading ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                    >
                        <p>
                            { loading ? t('pay.loading') : t('pay.button') }
                        </p>
                        <img className="button__arrow" src={Arrow} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Lead;
