import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import StepNav from '../components/partials/StepNav'

import Arrow from '../assets/arrow_svg.svg'
import '../styles/lead.css'
import axios from "axios";

const Lead = (props) => {
    const location = useLocation()
    const { t } = useTranslation()

    useEffect(() =>{
        props.setStep(2);

        // console.log(props.data)

        if (props.data) {
            emailRef.current.value = props.data.email
            nameRef.current.value = props.data.name
            check1Ref.current.checked = check2Ref.current.checked = true
        }
    })

    const history = useHistory()

    const emailRef = useRef()
    const nameRef = useRef()
    const check1Ref = useRef()
    const check2Ref = useRef()

    const handleSubmit = (subType) => {
        if (emailRef.current.value !== '' && nameRef.current.value !== '') {
            if (emailRef.current.value !== '' && !props.validateEmail(emailRef.current.value)) {
                props.throwMsg('E-mail není vyplněn správně.', document.querySelector('#lead-err'), 3000)
            } else if(!check1Ref.current.checked) {
                props.throwMsg('Pro pokračování musíte souhlasit se zpracováním osobních údajů.', document.querySelector('#lead-err'), 3000)
            } else if(!check2Ref.current.checked) {
                    props.throwMsg('Pro pokračování musíte souhlasit se obchodními podmínkami.', document.querySelector('#lead-err'), 3000)
            } else {
                history.push('/krok2')
                props.setData(
                    {
                        email: emailRef.current.value,
                        name: nameRef.current.value,
                        myself: subType === 'self' ? true : false,
                        products: props.data.products ? props.data.products : ''
                    }
                )

                fetch('/checkout/index.php/api/notify', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data:{
                            email: emailRef.current.value,
                            name: nameRef.current.value,
                            myself: subType === 'self' ? true : false,
                            products: props.data.products ? props.data.products : ''
                        }
                    }),
                })
                    .then(res => {
                        // window.location.href = res.data.url
                    })
            }
        } else {
            props.throwMsg('Vyplňte, prosím, všechna povinná pole.', document.querySelector('#lead-err'), 3000)
        }
    }

    return (
        <section className="lead-form-page page">
            <StepNav location={location.pathname} name="O VÁS" step={props.step} data={props.data} />
            <div className="header">
                <div className="header__title">
                    <h1>
                        {t("lead.header.title")}
                    </h1>
                </div>
                <div className="header__subtitle header__subtitle--text">

                </div>
            </div>
            <div className="contact">
                <div className="form-message form-message--err" id="lead-err"></div>

                <form className="contact__form lead__form" action="" method="POST">
                    <input name="lead_receivable"
                        type="text"
                        placeholder={t("lead.contact.receivable")}
                        ref={ nameRef }
                    />
                    <input name="lead_mail"
                        type="email"
                        placeholder="*E-mail"
                        required ref={ emailRef }
                    />
                    <div className="lead__check form-check">
                        <input type="checkbox" className="lead__check" ref={ check1Ref } />
                        <label className="gdpr-line">
                            {t("lead.contact.gdpr.agree")} <a href="https://www.finio.cz/zpracovani-osobnich-udaju" target="_blank" className="gdpr-link">{t("lead.contact.gdpr.link")}</a>.
                        </label>
                    </div>
                    <div className="lead__check form-check">
                        <input type="checkbox" className="lead__check" ref={ check2Ref } />
                        <label className="gdpr-line">
                            {t("lead.contact.check.agree")} <a href="https://www.finio.cz/vop" target="_blank" className="gdpr-link">{t("lead.contact.check.link")}</a>.
                        </label>
                    </div>
                </form>
            </div>

            <div className="button-container">
                <div className="pohledavky__send" onClick={ () => handleSubmit('self') }>
                    <button className="button">
                        <p>{t("lead.buttons.1")}</p>
                        <img className="button__arrow" src={ Arrow } alt="šipka" />
                    </button>
                </div>
                <div className="button-container__dots">
                    <div className="button-container__dots__text">
                        {t("lead.or")}
                    </div>
                </div>
                <div className="pohledavky__send" onClick={ () => handleSubmit('other') }>
                    <button className="button">
                        <p>{t("lead.buttons.2")}</p>
                        <img className="button__arrow" src={ Arrow } alt="šipka" />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Lead;
