import { useEffect } from 'react'
import '../../styles/step-nav.css'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const StepNav = (props) => {

    const { t } = useTranslation();

    const updateData = () => {
        if (props.setData) {
            props.setData({
                ...props.data,
                products: props.products,
                totalPrice: props.getPrice()
            })
        }
    }

    return (
        <div className="step-nav">
            <div className={props.step === 2 ? 'step-nav__item step-nav__item--active' : 'step-nav__item'}>
                <Link to='/krok1'>
                    <b>{t('nav.step')} 1</b>
                    <small>{t('nav.authority')}</small>
                </Link>
            </div>
            <div className={props.step === 3 ? 'step-nav__item step-nav__item--active' : 'step-nav__item'}>
                {props.data.email ?
                    <Link to="/krok2">
                        <b>{t('nav.step')} 2</b>
                        <small>{t('nav.verified')}</small>
                    </Link>
                    :
                    <>
                        <b>{t('nav.step')} 2</b>
                        <small>{t('nav.verified')}</small>
                    </>
                }
            </div>
            <div className={props.step === 4 ? 'step-nav__item step-nav__item--active' : 'step-nav__item'}
                onClick={() => updateData()}>
                {props.data.products && props.data.products.length > 0 ?
                    <Link to="/krok3">
                        <b>{t('nav.step')} 3</b>
                        <small>{t('nav.recapitulation')}</small>
                    </Link>
                    :
                    <>
                        <b>{t('nav.step')} 3</b>
                        <small>{t('nav.recapitulation')}</small>
                    </>
                }
            </div>
            <div className="step-nav__item">
                <>
                    <b>{t('nav.step')} 4</b>
                    <small>{t('nav.payment')}</small>
                </>
            </div>
        </div>
    )
}

export default StepNav